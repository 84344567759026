import React from "react";
import Input from "./Input";
import { Box, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import UndoIcon from "@mui/icons-material/Undo";
import ComboBox from "./ComboBox";
import { priceLevels } from "../constants";
import toast from "react-hot-toast";

export default function LineItemForm({
  data,
  lineItems = [],
  setLineItems,
  allLineItems,
  errors,
  priceLevel,
  ...props
}) {
  const newLineItem = {
    item: "",
    rate: "0",
    quantity: "1",
    pricelevel: priceLevel,
    amount: "0",
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const createLineItem = () => setLineItems([...lineItems, { ...newLineItem }]);

  const handleRemove = (index) => () => {
    const tempLineItems = [...lineItems];
    tempLineItems.splice(index, 1);
    setLineItems(tempLineItems);
  };

  const handleUndoDelete = (index) => () => {
    const tempLineItems = [...lineItems];
    tempLineItems[index].markForDelete = false;
    setLineItems(tempLineItems);
  };

  const calculateRate = async (index) => {
    const currLineItems = [...lineItems];
    const pricelevel = parseInt(currLineItems[index].pricelevel, 10); // Ensure pricelevel is a number
    const itemId = currLineItems[index].item;

    // Skip rate calculation for custom price level (-1)
    if (pricelevel === -1) {
      return;
    }

    if (!itemId) {
      return;
    }

    const pricelevelname = priceLevels.find(
      (level) => level.id == pricelevel
    )?.name;
    const method = "POST";
    const url = `${process.env.REACT_APP_BASE_URL}/api/salesorders/item-rate`;
    const body = JSON.stringify({ itemId, pricelevelname });
    const res = await fetch(url, {
      method: method,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: body,
    });

    const data = await res.json();

    if (res.status === 200) {
      const tempLineItems = [...lineItems];
      tempLineItems[index]["rate"] = data.price;
      tempLineItems[index]["amount"] =
        data.price * tempLineItems[index]["quantity"];
      setLineItems(tempLineItems);
    } else if (res.status === 404) {
      const tempLineItems = [...lineItems];
      if (data != undefined) {
        tempLineItems[index]["rate"] = data.price;
        tempLineItems[index]["amount"] =
          data.price * tempLineItems[index]["quantity"];
      } else {
        tempLineItems[index]["rate"] = 0;
        tempLineItems[index]["amount"] = 0;
      }
      tempLineItems[index]["pricelevel"] = 1; // Fallback to base price level
      setLineItems(tempLineItems);
      toast.error(
        "No rate for this item and price level. Going to Base Price."
      );
    }
  };

  const handleChange = (index, value, property) => {
    const updatedList = [...lineItems];
    updatedList[index][property] = value.toString();

    // Recalculate amount if quantity or rate changes
    if (
      property === "quantity" ||
      (property === "rate" &&
        parseInt(updatedList[index].pricelevel, 10) === -1)
    ) {
      updatedList[index]["amount"] =
        updatedList[index]["quantity"] * updatedList[index]["rate"];
    }

    setLineItems(updatedList);
  };

  return (
    <>
      {lineItems.map((lineItem, index) => (
        <Box className="flex w-full" key={lineItem.id + " " + index}>
          <Box className="w-1/3 mr-2">
            <ComboBox
              options={Object.values(allLineItems).map((lineItem) => ({
                id: lineItem.id,
                name: lineItem.sku + " - " + lineItem.description,
              }))}
              value={lineItem.item}
              onChange={(event, value) => {
                handleChange(index, value.id, "item");
                if (parseInt(lineItem.pricelevel, 10) !== -1) {
                  // Only calculate rate if not custom
                  calculateRate(index);
                }
              }}
              placeholder="Line Item"
              className="py-1"
            >
              Item
            </ComboBox>
          </Box>
          <Box className="w-1/5 mr-2">
            <ComboBox
              options={priceLevels}
              value={lineItem.pricelevel}
              onChange={(event, value) => {
                handleChange(index, value.id, "pricelevel");
                if (parseInt(value.id, 10) !== -1) {
                  // Only calculate rate if not custom
                  calculateRate(index);
                }
              }}
              className="py-1"
            >
              Price Level
            </ComboBox>
          </Box>
          <Box className="w-1/5 mr-2">
            <Input
              value={lineItem.quantity}
              onChange={(event) =>
                handleChange(index, event.target.value, "quantity")
              }
              placeholder="Quantity"
            />
          </Box>
          <Box className="w-1/5 mr-2">
            <Input
              disabled={parseInt(lineItem.pricelevel, 10) !== -1} // Enable only for custom price level
              value={lineItem.rate}
              onChange={(event) =>
                handleChange(index, event.target.value, "rate")
              }
              placeholder="Rate"
            />
          </Box>
          <Box className="w-1/5 mr-2">
            <Input
              disabled={true}
              value={formatter.format(lineItem.amount)}
              onChange={(event) =>
                handleChange(index, event.target.value, "amount")
              }
              placeholder="Amount"
            />
          </Box>
          {lineItem.markForDelete ? (
            <Box className="flex-shrink" title="Undo delete">
              <IconButton
                onClick={handleUndoDelete(index)}
                color="success"
                className="float-right"
              >
                <UndoIcon className="-mt-2 mb-1" fontSize="large" />
              </IconButton>
            </Box>
          ) : (
            <Box className="flex-shrink" title="Remove row">
              <IconButton
                onClick={handleRemove(index)}
                color="error"
                className="float-right"
              >
                <RemoveCircleIcon className="-mt-2 mb-1" fontSize="large" />
              </IconButton>
            </Box>
          )}
        </Box>
      ))}

      <Box className="w-full">
        <IconButton
          onClick={createLineItem}
          className="float-right"
          color="success"
        >
          <AddIcon fontSize="large" />
        </IconButton>
      </Box>
    </>
  );
}
